<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron registros de tiempo trabajado"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idtiempo_trabajado"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <tiempo-trabajado-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :readonly="slotProps.item.idparte_trabajo !== null"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <tiempo-trabajado-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="hasViewPerm(permissions.tiempos.introducirManual)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './TiempoTrabajadoListData'
import TiempoTrabajadoListItem from './components/TiempoTrabajadoListItem'
import TiempoTrabajadoListLegend from './components/TiempoTrabajadoListLegend'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesTiempoList'

export default {
  components: {
    TiempoTrabajadoListItem, TiempoTrabajadoListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false,
        search: false,
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    cronometroIdparte: get('cronometro/idparte'),
    usuarioIdempleado: get('usuario/idempleado'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Tiempo trabajado'
      if (this.cronometroIdparte) {
        this.$alert.showSnackbarWarning(
          `Tienes el cronómetro inciado para el parte de trabajo Nº${this.cronometroIdparte}`
        )
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectTiempoTrabajado(
          this,
          this.usuarioIdempleado,
          this.filter,
          this.sorter,
          this.currentPage,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectTiempoTrabajadoRows(this, dirty.modified), dirty, 'idtiempo_trabajado'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'tiempos__tiempo-add',
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el tiempo trabajado del día ${this.$options.filters.shortDate(data.item.finicio)}?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deleteTiempoTrabajado(this, data.item.idtiempo_trabajado)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.ttiempoTrabajado.items.length === 0 &&
        this.filter.ttarea.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@ttiempoTrabajado.items', resp.data.selectTtiempoTrabajado.result.dataset)
        await this.setStoreProperty('filter@ttarea.items', resp.data.selectTtarea.result.dataset)
      }
    },
  },
}
</script>
